import { iframeResize } from 'iframe-resizer';

export default function () {
  /**
   * Use iframe-resizer to allow child iframes to resize to fit their content.
   * @see https://github.com/davidjbradshaw/iframe-resizer
   *
   * `checkOrigin: false` is because the iframe source may be redirected to the Cloudfront domain.
   */
  iframeResize({ checkOrigin: false }, '[data-iframe-embed]');
}
