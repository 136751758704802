/*
 * Webpack does not know the 'static' URL of the CDN it will be served from at build time.
 *
 * The Django template file *does* know though, so writes that to window.staticRoot.
 *
 * This is the recommended approach as per webpack's documentation, here:
 * https://webpack.js.org/concepts/output/#advanced
 */

declare let __webpack_public_path__: string;

__webpack_public_path__ = (window as any).staticRoot;
