import KeyCodes from '../../utils/keycodes';

const classNames = {
  ACTIVE_OVERLAY: 'drawer-overlay--is-active',
  ACTIVE_DRAWER: 'drawer--is-open',
  // These also need to be updated in MenuDrawerLayers.js
  OPEN_SECOND_LAYER: 'drawer__group--secondary-open',
  DRAWER_LIST_VISIBLE: 'drawer__list--is-visible',
};

export default function () {
  const triggers = [...document.querySelectorAll('[data-menu="trigger"]')];
  if (!triggers) return;
  const drawer = document.querySelector('[data-menu="drawer"]');
  const overlay = document.querySelector('[data-menu-overlay]');
  let isDrawerOpen = false;

  const toggle = (e?: Event) => {
    if (e) e.preventDefault();

    if (isDrawerOpen) {
      // Open to close
      const group = drawer.querySelector<HTMLElement>('[data-menu="group"]');
      drawer.classList.remove(classNames.ACTIVE_DRAWER);
      overlay.classList.remove(classNames.ACTIVE_OVERLAY);
      drawer.setAttribute('aria-hidden', true.toString());
      isDrawerOpen = false;

      if (group.classList.contains(classNames.OPEN_SECOND_LAYER)) {
        group.classList.remove(classNames.OPEN_SECOND_LAYER);

        const menuList = document.querySelector(
          `.${classNames.DRAWER_LIST_VISIBLE}`,
        );

        if (menuList) menuList.classList.remove(classNames.DRAWER_LIST_VISIBLE);
      }
    } else {
      // Close to open
      drawer.classList.add(classNames.ACTIVE_DRAWER);
      overlay.classList.add(classNames.ACTIVE_OVERLAY);
      drawer.setAttribute('aria-hidden', false.toString());
      isDrawerOpen = true;
    }
  };

  const handleKeyDown = (e) => {
    if (isDrawerOpen && e.keyCode === KeyCodes.ESCAPE) toggle();
  };

  triggers.forEach((trigger) => {
    trigger.addEventListener('click', toggle);
  });
  window.addEventListener('keydown', handleKeyDown);
}
