import KeyCodes from '../../utils/keycodes';
import { transitionEventEnd } from '../../utils';

const transitionEvent = transitionEventEnd();

const classNames = {
  BACKGROUND_TRANS: 'menu-dropdown-background--trans',
  BACKGROUND_VISIBLE: 'dropdown-background--is-visible',
  MENU_LINK_OPEN: 'menu__link--is-open',
  MENU_DROPDOWN_OPEN: 'menu-dropdown--is-open',
};

// TODO: accessibility with the tab key,
// and what happens when the window gets resized smaller.

export default function () {
  const triggers = [...document.querySelectorAll('[data-dropdown-link]')];
  if (!triggers) return;

  const body = document.querySelector<HTMLElement>('body');
  const background = document.querySelector<HTMLElement>(
    '[data-dropdown="background"]',
  );
  const lists = [
    ...document.querySelectorAll<HTMLElement>('[data-dropdown-list]'),
  ];
  const logoSVG = document.querySelector<HTMLElement>(
    '[aria-roledescription="logo"] svg use',
  );
  let dropdownIsOpen = false;
  let dropdownID = 0;

  const setTabIndex = (els, index) => {
    els.forEach((el) => {
      el.setAttribute('tabIndex', index);
    });
  };

  const getHeight = (elm) => {
    const height =
      elm.clientHeight + elm.getBoundingClientRect().top + elm.offsetTop + 24; // the 24 is used for padding below nav.
    return height;
  };

  const transitionEnd = (e) => {
    const { target } = e;
    if (target !== background) {
      return;
    }

    background.classList.remove(classNames.BACKGROUND_TRANS);
    background.style.height = '0px';
    background.removeEventListener(transitionEvent, transitionEnd);
  };

  const openMenuBackground = (elm) => {
    const height = getHeight(elm);
    background.style.transform = `translate(0, -${height}px)`;
    background.style.height = `${height}px`;

    setTimeout(() => {
      body.classList.add(classNames.BACKGROUND_VISIBLE);
      background.style.transform = 'translate(0, 0)';
      background.classList.add(classNames.BACKGROUND_TRANS);
    }, 50);
  };

  const closeMenuBackground = (elm) => {
    const height = getHeight(elm);
    background.style.transform = `translate(0, -${height}px)`;
    body.classList.remove(classNames.BACKGROUND_VISIBLE);
    background.addEventListener(transitionEvent, transitionEnd);
  };

  const updateMenuBackground = (elm) => {
    const height = getHeight(elm);
    background.style.height = `${height}px`;
  };

  const toggle = (e?: Event) => {
    let target;
    let id = dropdownID;

    if (e) {
      e.preventDefault();
      // @ts-ignore
      id = e.currentTarget.getAttribute('data-dropdown-link');
      target = document.querySelector(`[data-dropdown-link="${id}"]`);
    } else {
      target = document.querySelector(`[data-dropdown-link="${id}"]`);
    }

    const list = document.querySelector(`[data-dropdown-list="${id}"]`);
    const links = [...list.querySelectorAll('a')];

    // Toggle the logo version
    if (dropdownIsOpen) {
      setTimeout(() => {
        logoSVG.setAttribute('xlink:href', '#logo-now');
      }, 100);
    } else {
      setTimeout(() => {
        logoSVG.setAttribute('xlink:href', '#logo-now-reversed');
      }, 100);
    }

    if (dropdownIsOpen && dropdownID !== id) {
      // Change Menu
      if (dropdownID === 0) return;
      const currentTarget = document.querySelector(
        `[data-dropdown-link="${dropdownID}"]`,
      );
      const currentList = document.querySelector(
        `[data-dropdown-list="${dropdownID}"]`,
      );
      const currentLinks = [...currentList.querySelectorAll('a')];

      currentTarget.classList.remove(classNames.MENU_LINK_OPEN);
      currentList.classList.remove(classNames.MENU_DROPDOWN_OPEN);

      currentTarget.setAttribute('aria-expanded', 'false');
      currentList.setAttribute('aria-hidden', 'true');
      currentList.setAttribute('aria-expanded', 'false');

      target.classList.add(classNames.MENU_LINK_OPEN);
      list.classList.add(classNames.MENU_DROPDOWN_OPEN);

      target.setAttribute('aria-expanded', 'true');
      list.setAttribute('aria-hidden', 'false');
      list.setAttribute('aria-expanded', 'true');

      dropdownID = id;
      setTabIndex(links, 0);
      setTabIndex(currentLinks, -1);
      updateMenuBackground(list);
    } else if (dropdownIsOpen) {
      // Close Menu
      target.classList.remove(classNames.MENU_LINK_OPEN);
      list.classList.remove(classNames.MENU_DROPDOWN_OPEN);

      target.setAttribute('aria-expanded', 'false');
      list.setAttribute('aria-hidden', 'true');
      list.setAttribute('aria-expanded', 'false');

      dropdownIsOpen = false;
      dropdownID = 0;

      setTabIndex(links, -1);
      closeMenuBackground(list);
    } else {
      // Open Menu
      target.classList.add(classNames.MENU_LINK_OPEN);
      list.classList.add(classNames.MENU_DROPDOWN_OPEN);

      target.setAttribute('aria-expanded', 'true');
      list.setAttribute('aria-hidden', 'false');
      list.setAttribute('aria-expanded', 'true');

      list.lastElementChild.addEventListener(
        'keydown',
        // eslint-disable-next-line no-use-before-define
        handleLastChildKeyDown,
      );

      dropdownIsOpen = true;
      dropdownID = id;

      setTabIndex(links, 0);
      openMenuBackground(list);
    }
  };

  const handleLastChildKeyDown = (e) => {
    if (dropdownIsOpen && e.keyCode === KeyCodes.TAB) toggle();
  };

  const handleKeyDown = (e) => {
    if (dropdownIsOpen && e.keyCode === KeyCodes.ESCAPE) toggle();
  };

  lists.forEach((list) => {
    list.setAttribute('aria-hidden', 'true');
    list.setAttribute('aria-expanded', 'false');
    const links = [...list.querySelectorAll('a')];
    setTabIndex(links, -1);
  });

  triggers.forEach((trigger) => {
    trigger.setAttribute('aria-expanded', 'false');
    trigger.setAttribute('aria-haspopup', 'true');
    trigger.addEventListener('click', toggle);
  });

  window.addEventListener('keydown', handleKeyDown);
}
