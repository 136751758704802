// =============================================================================
// Utilities
// =============================================================================
// Add your utility functions in this file.

export const querySelectArray = (selector, scope = document) =>
    // eslint-disable-next-line no-restricted-properties
    Array.prototype.slice.call(scope.querySelectorAll(selector));

// Remove CSS outlines in an accessible manner
// Make sure you have an empty style tag that
// lives after your main style sheet
export function tabFocus(selector = '.accessTab') {
    const tabFocusElmt = document.querySelector(selector);
    window.addEventListener('mousedown', () => {
        tabFocusElmt.innerHTML = '';
    });

    window.addEventListener('keydown', e => {
        const isTabKey = e.keyCode === 9;
        if (isTabKey) {
            tabFocusElmt.innerHTML = `
                a:focus, input:focus, input:focus + label, select:focus, textarea:focus, button:focus { outline: solid 3px #ffe830; }`;
        }
    });
}

// export function requestAnimFrame() {
//     return (
//         window.requestAnimationFrame ||
//         window.webkitRequestAnimationFrame ||
//         window.mozRequestAnimationFrame ||
//         function(callback) {
//             window.setTimeout(callback, 1000 / 60);
//         }
//     );
// }

export function transitionEventEnd() {
    /* eslint-disable */
    let i;
    const el = document.createElement('fakeelement'),
        transitions = {
            transition: 'transitionend',
            OTransition: 'otransitionend',
            MozTransition: 'transitionend',
            WebkitTransition: 'webkitTransitionEnd',
        };

    for (i in transitions) {
        if (transitions.hasOwnProperty(i) && el.style[i] !== undefined) {
            return transitions[i];
        }
    }

    return;
    /* eslint-enable */
}
