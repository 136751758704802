/* eslint springload/import/first: 0 */
import './js/public-path'; // MUST be first (yes, before absolute imports)
import 'flickity';
import 'flickity-imagesloaded';

import './sass/styles.scss';

import IFrame from './js/components/iframe/iframe';
import MenuDrawer from './js/components/menuDrawer/MenuDrawer';
import MenuDrawerLayers from './js/components/menuDrawer/MenuDrawerLayers';
import MenuDropdown from './js/components/menuDropdown/MenuDropdown';
import MenuBackButton from './js/components/MenuBack/MenuBackButton';

// If you are using TAB focus for accessibilty import it here:
// alternatively you could use npm package 'what-input' if you prefer
import { tabFocus } from './js/utils';

const site = {
  /**
   * Initialises the site's modules.
   * Each module defines its own init function, this is just the glue.
   *
   */
  init() {
    tabFocus();
    IFrame();
    MenuDrawer();
    MenuDrawerLayers();
    MenuDropdown();
    MenuBackButton();
  },
};

site.init();
