const classNames = {
  // These also need to be updated in MenuDrawer.js
  OPEN_SECOND_LAYER: 'drawer__group--secondary-open',
  DRAWER_LIST_VISIBLE: 'drawer__list--is-visible',
};

export default function () {
  const triggers = [
    ...document.querySelectorAll<HTMLElement>('[data-menu-id]'),
  ];
  if (!triggers) return;

  const group = document.querySelector<HTMLElement>('[data-menu="group"]');
  const list = [...document.querySelectorAll<HTMLElement>('[data-menu-list]')];
  const backButtons = [...document.querySelectorAll('[data-menu-list="back"]')];

  const findAncestor = (el, cls) => {
    // eslint-disable-next-line no-cond-assign, no-param-reassign
    while ((el = el.parentNode) && !el.classList.contains(cls));
    return el;
  };

  const moveBack = (e) => {
    e.preventDefault();

    const ancestor = findAncestor(e.target, 'drawer__list');
    ancestor.classList.remove(classNames.DRAWER_LIST_VISIBLE);
    group.classList.remove(classNames.OPEN_SECOND_LAYER);
  };

  const moveFoward = (e) => {
    e.preventDefault();
    group.classList.add(classNames.OPEN_SECOND_LAYER);
    const attr = e.target.getAttribute('data-menu-id');

    list.forEach((item) => {
      const listAttr = item.getAttribute('data-menu-list');
      if (listAttr === attr) {
        item.classList.add(classNames.DRAWER_LIST_VISIBLE);
      }
    });
  };

  triggers.forEach((trigger) => {
    trigger.addEventListener('click', moveFoward);
  });

  backButtons.forEach((btn) => {
    btn.addEventListener('click', moveBack);
  });
}
